import axios from "axios";
import { showErrorToast } from "../../utils/toastUtils";

export const apiGetCustomersList = () => {
  return axios.get("/api/user/user-customers-list").then((result) => {
    if (result) {
      if (result.data) {
        const data = result.data.customersWithContacts;
        data.map((item) => {
          item.text = item.companyName ? item.companyName : "";
          item.value = item.id;
        });
        return data;
      }
    }
    return false;
  });
};

export const apiGetContactsList = (id) => {
  return axios.get(`/api/user/customer-contacts/${id}`).then((result) => {
    if (result) {
      if (result.data) {
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetMyUsersList = () => {
  return axios.get("/api/user/my-users-with-current").then((result) => {
    if (result) {
      if (result.data) {
        result.data.sort((a, b) => a.fullName.localeCompare(b.fullName));
        result.data.map((item) => {
          item.text = item.fullName;
          item.value = item.id;
        });
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetMyUsersListWithCurrent = () => {
  return axios.get("/api/user/my-users-with-current").then((result) => {
    if (result) {
      if (result.data) {
        result.data.sort((a, b) => a.fullName.localeCompare(b.fullName));
        result.data.map((item) => {
          item.text = item.fullName;
          item.value = item.id;
        });
        let filteredItems = result.data.filter((item) => item.isAccepted);
        return filteredItems;
      }
    }
    return false;
  });
};

export const apiGetMyBOAPodList = () => {
  return axios.get("/api/BOApod/my-boapods").then((result) => {
    if (result) {
      if (result.data) {
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetUserTypeList = () => {
  const userTypeData = localStorage.getItem("userType");
  const userType = Number(userTypeData);
  return axios.get("/api/user/user-types").then((result) => {
    if (result) {
      if (result.data) {
        result.data.map((item) => {
          item.text = item.name;
          item.value = item.id;
        });
        if (userType === 3 || userType === 6 || userType === 9) {
          var data = result.data.filter(function (obj) {
            return obj.name !== "Owner";
          });
          return data;
        }
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetStockLocationList = () => {
  const payload = {
    withPods: true,
  };
  return axios
    .get("/api/boapod/stock-locations", { params: payload })
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.stockLocations.sort((a, b) =>
            a.stockLocationName.localeCompare(b.stockLocationName)
          );
          result.data.stockLocations.map((item) => {
            item.text = item.stockLocationName;
            item.value = item.id;
          });
          return result.data.stockLocations;
        }
      }
      return false;
    });
};

export const apiGetVisibilityOptions = () => {
  return axios.get("/api/BOApod/visibility-options").then((result) => {
    if (result) {
      if (result.data) {
        result.data.map((item) => {
          item.text = item.name;
          item.value = item.id;
        });
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetAssetLocationsList = () => {
  return axios.get("/api/Assets/asset-locations").then((result) => {
    if (result) {
      if (result.data) {
        result.data.sort((a, b) => {
          const fullNameA = a.locationName || "";
          const fullNameB = b.locationName || "";
          return fullNameA.localeCompare(fullNameB);
        });
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetUser = (id) => {
  return axios.get(`/api/user/my-users/${id}`).then((result) => {
    console.log(result);
    if (result) {
      if (result.status === 203) {
        return false;
      }
    }
    if (result && result.data) {
      return result.data;
    }
    return false;
  });
};

export const apiInviteUser = async (data) => {
  data.industryId = Number(data.industryId);
  return axios
    .post(`/api/user/invite`, data)
    .then((result) => {
      if (result && result.data) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        const errorMessages = data.errors;

        const countTotalErrors = (errorMessages) => {
          let totalErrors = 0;
          for (const field in errorMessages) {
            if (errorMessages.hasOwnProperty(field)) {
              totalErrors += errorMessages[field].length;
            }
          }
          return totalErrors;
        };

        // Get the total number of error messages
        const totalErrors = countTotalErrors(errorMessages);

        // Log the appropriate message
        if (totalErrors > 1) {
          showErrorToast("Please complete all required fields");
        } else if (totalErrors === 1) {
          // Log the first error message if there is only one error
          for (const field in errorMessages) {
            if (
              errorMessages.hasOwnProperty(field) &&
              errorMessages[field].length > 0
            ) {
              showErrorToast(errorMessages[field][0]);
              break;
            }
          }
        } else {
          console.log("No errors found");
        }
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiAddCustomer = async (data, userName) => {
  data.userName = userName;
  return axios
    .post(`/api/user/customer`, data)
    .then((result) => {
      console.log(result);
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        if (error.response.status === 403)
          showErrorToast("A Customer with this name already exists");
      } else {
        console.log("Error:", error.message);
      }
    });
};

export const apiAddContact = async (data, id) => {
  data.customerId = id;
  return axios.post(`/api/user/customer-contacts`, data).then((result) => {
    if (result && result.data) {
      return result.data;
    }
    return false;
  });
};

export const apiAddStockLocation = async (data) => {
  return axios
    .post(`/api/boapod/stock-location`, data)
    .then((result) => {
      console.log(result);
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        if (error.response.status === 403)
          showErrorToast("A Stock Location with this name already exists");
      } else {
        console.log("Error:", error.message);
      }
    });
};

export const apiUpdateStockLocation = async (data) => {
  return axios
    .put(`/api/boapod/update-stocklocation/${data.id}`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    });
};

export const apiAddAssetLocation = async (data) => {
  return axios
    .post(`/api/Assets/asset-location`, data)
    .then((result) => {
      console.log(result);
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        if (error.response.status === 403)
          showErrorToast("An Asset Location with this name already exists");
      } else {
        console.log("Error:", error.message);
      }
    });
};

export const apiUpdateAssetLocation = async (data) => {
  return axios.put(`/api/Assets/asset-location`, data).then((result) => {
    if (result && result.status) {
      if (result.status === 200 || result.status === 204) return data;
    }
    return false;
  });
};

export const apiSaveCustomersAndContacts = async (data) => {
  const postData = { customersWithContacts: data };
  return axios
    .post(`/api/user/customer-contacts-bulk`, postData)
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiResendInviteUser = async (id) => {
  return axios.post(`/api/user/invite/resend/${id}`).then((result) => {
    if (result && result.data) {
      return true;
    }
    return false;
  });
};

export const apiUpdatePodContactDetails = async (pod) => {
  const payload = {
    email: pod.email,
    phone: pod.driverContactNumber,
  };
  return axios
    .put(`/api/BOApod/my-boapod/${pod.deviceId}/update-contact`, payload)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

export const apiUpdatePodAvailability = async (pod, isActive, time) => {
  const payload = {
    isActive: isActive,
    time: isActive ? 5 : time,
  };
  return axios
    .put(`/api/BOApod/my-boapod/${pod.deviceId}/update-visibility`, payload)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

export const apiCancelInviteUser = async (id) => {
  return axios
    .patch(`/api/user/my-users/cancel-invite/${id}`)
    .then((result) => {
      if (result) {
        if (result.status === 204) {
          return true;
        }
      }
      return false;
    });
};

export const apiUpdateUser = async (data) => {
  data.industryId = Number(data.industryId);
  return axios
    .patch(`/api/user/my-users/update/${data.id}`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    });
};

export const apiDeleteUser = async (data) => {
  return axios
    .delete(`/api/user/my-users/remove/${data.id}`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    });
};

export const apiDeleteCustomer = async (includeContacts, data) => {
  return axios
    .delete(
      `/api/user/delete-customer?deleteContacts=${includeContacts}&id=${data.id}`,
      data
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    });
};

export const apiDeleteContact = async (data) => {
  return axios
    .delete(`/api/user/delete-customercontact/${data.id}`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    });
};

export const apiDeleteStockLocation = async (data) => {
  const payload = {
    id: data.id,
  };
  return axios
    .delete(`/api/boapod/delete-stocklocation`, { params: payload })
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDeleteAssetLocation = async (data) => {
  const payload = {
    assetLocId: data.id,
  };
  return axios
    .delete(`/api/Assets/asset-location`, { params: payload })
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiGetBOApods = (location) => {
  const data = {
    myLatitude: location.lat,
    myLongitude: location.lng,
    maxDistance: 1000000000,
    PageNo: 1,
    Limit: 1000,
  };
  return axios.get("/api/BOApod/fetch", { params: data }).then((result) => {
    if (result) {
      if (result.data) {
        result.data.records.sort((a, b) => {
          return a.position.distance - b.position.distance;
        });
        return result.data.records;
      }
    }
    return false;
  });
};

export const apiGetBanners = (type) => {
  return axios.get(`/api/user/banners?envType=${type}`).then((result) => {
    if (result) {
      console.log(result);
      if (result.data) {
        return result.data;
      }
    }
    return false;
  });
};

export const apiUploadCompanyLogo = async (data) => {
  const formData = new FormData();

  formData.append("file", data);
  return axios
    .post(`/api/Upload/upload-company-logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiRemoveCompanyLogo = async () => {
  return axios.put("/api/Upload/remove-company-logo").then((result) => {
    if (result && result.status) {
      if (result.status === 200 || result.status === 204) return true;
    }
    return false;
  });
};

export const apiGetCustomPricing = () => {
  return axios.get("/api/user/get-company-settings").then((result) => {
    if (result) {
      if (result.data) {
        const data = result.data;
        if (data.useBoaRrp === null && data.customGlobalMarkup === null) {
          data.useBoaRrp = true;
        }
        if (data.crimpFeeMarkupPercent === null) {
          data.useEach = true;
        }
        if (data.crimpFeeEach === null && data.crimpFeeMarkupPercent === null) {
          data.crimpFeeEach = 10;
          data.useEach = true;
        }
        return data;
      }
    }
    return false;
  });
};

export const apiUpdateCustomPricing = async (data) => {
  delete data.id;
  delete data.name;
  data.customGlobalMarkup = Number(data.customGlobalMarkup);
  data.labourFee = Number(data.labourFee);
  data.travel = Number(data.travel);
  data.crimpFeeEach = Number(data.crimpFeeEach);
  data.crimpFeeMarkupPercent = Number(data.crimpFeeMarkupPercent);
  if (data.useEach) {
    data.crimpFeeMarkupPercent = null;
  } else {
    data.crimpFeeEach = null;
  }
  return axios.put(`/api/user/update-company-settings`, data).then((result) => {
    if (result && result.status) {
      if (result.status === 200 || result.status === 204) return data;
    }
    return false;
  });
};

export const apiGenerateXeroToken = (code, state) => {
  const payload = {
    code: code,
    state: state,
  };
  return axios
    .post("/api/user/xero-connect", payload)
    .then((result) => {
      if (result) {
        if (result.data) {
          const data = result.data;
          return data;
        }
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        showErrorToast(data?.description);
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDisconnectXero = () => {
  return axios
    .post("/api/user/xero-disconnect")
    .then((result) => {
      if (result) {
        if (result.data) {
          const data = result.data;
          return data;
        }
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        showErrorToast(data?.description);
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

// RS23 Training Certificate

function toLocalISOString(date) {
  const pad = (number) => {
    return number < 10 ? "0" + number : number;
  };

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are zero-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = String((date.getMilliseconds() / 1000).toFixed(3)).slice(
    2,
    5
  );

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export const apiUploadTrainingCertificate = async (data, userId) => {
  const formData = new FormData();

  formData.append("file", data);
  return axios
    .post(`/api/user/upload-rs23-certificate?userId=${userId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiDeleteTrainingCertificate = async (userId) => {
  return axios
    .delete(`/api/user/delete-user-rs23training-cert?userId=${userId}`)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

export const apiUpdateTrainingDates = async (date1, date2, userId) => {
  var trainDateData = new Date(date1);
  trainDateData.setHours(0, 0, 0, 0);
  const trainDate = toLocalISOString(trainDateData);
  var reTrainDateData = new Date(date2);
  reTrainDateData.setHours(0, 0, 0, 0);
  const reTrainDate = toLocalISOString(reTrainDateData);
  const payload = {
    trainDate: date1 ? trainDate : null,
    reTrainDate: date2 ? reTrainDate : null,
  };
  return axios
    .put(`/api/user/update-user-rs23cert-traindates?userId=${userId}`, payload)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};
