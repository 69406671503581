import axios from "axios";
import { padZero } from "../../utils/utils";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const apiGetOrderHistoryList = (search, technician) => {
  const data = {
    textSearch: search,
    technicianEmail: technician === "All" ? "" : technician,
  };
  return axios
    .get(`/api/SalesOrder/order-history`, { params: data })
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.map((data) => {
            if (data.confirmedDateTime !== null) {
              var dateObject = new Date(data.confirmedDateTime);

              // Format the date
              var formattedDate = `${padZero(dateObject.getDate())}/${padZero(
                dateObject.getMonth() + 1
              )}/${dateObject.getFullYear().toString().slice(2)}`;
              var hours = dateObject.getHours() % 12 || 12; // Convert to 12-hour format
              var formattedTime = `${padZero(hours)}:${padZero(
                dateObject.getMinutes()
              )} ${dateObject.getHours() < 12 ? "AM" : "PM"}`;
              // Combine date and time
              data.formattedDate = `${formattedDate}, ${formattedTime}`;
            }
          });
          return result.data;
        }
      }
      return false;
    });
};

export const apiGetOrderDetails = (id) => {
  const data = {
    cartId: id,
  };
  return axios
    .get(`/api/SalesOrder/order-details`, { params: data })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};
