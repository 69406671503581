import axios from "axios";
import { CART, PRICING_POLICY, GET_POLICY } from "../../constants/apiLink";
import { addSessionToken } from "../../utils/axios.utils";

export const apiUpdateCart = async (items) => {
  const stockLocationId = localStorage.getItem("boahubLocationId");
  items.boahubLocationId = Number(stockLocationId);
  return axios.post(CART, items).then((result) => {
    return result;
  });
};

export const apiPatchCart = async (items, action) => {
  const stockLocationId = localStorage.getItem("boahubLocationId");
  items.boahubLocationId = Number(stockLocationId);
  const actionParameter = action ? action : "";

  const payload = {
    currencyId: 0,
    userCartItems: [],
    updatedCart: items.updatedCart,
  };

  return axios
    .patch(CART + "?action=" + actionParameter, payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiCreateCart = async (items) => {
  const stockLocationId = localStorage.getItem("boahubLocationId");
  items.boahubLocationId = Number(stockLocationId);
  return axios.post(CART, items).then((result) => {
    return result;
  });
};

export const apiGetCartItems = async (items) => {
  return axios.get(CART).then((result) => {
    return result;
  });
};

export const apiGetStockCodePolicy = async (stockCode) => {
  const url = PRICING_POLICY + GET_POLICY + "?stockCode=" + stockCode;
  return axios.get(url).then((result) => {
    return result;
  });
};

export const apiDeleteCart = async () => {
  return axios.delete(CART).then((result) => {
    return result;
  });
};

export const apiGetUserCurrentStockLocation = async () => {
  return axios.get("/api/user/user-stocklocation").then((response) => {
    if (response) {
      if (response.data) {
        return response.data;
      }
    }
  });
};

export const apiGetUserCurrentStockLocationForChecking = async () => {
  return axios.get("/api/user/user-stocklocation").then((response) => {
    if (response) {
      if (response.data) {
        return response.data;
      }
    }
  });
};
