import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history } from "./store";
import App from "./containers/App";
import registerServiceWorker from "./registerServiceWorker";
import "sanitize.css/sanitize.css";
import "./index.css";
import "./utils/axios.utils";
import "./assets/fonts/boafont.css";

const _ = require("lodash");
window._ = _;

const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
);

registerServiceWorker();
