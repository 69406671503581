import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Menu, Icon, Dropdown, Label, Popup } from "semantic-ui-react";

const CommonMenu = ({
  activeItem,
  handleItemClick,
  visibility,
  getCartCount,
  isFacility,
  logout,
}) => {
  const [hasJobbingAccess, setHasJobbingAccess] = useState(false);

  const handleClick = (e, data) => {
    e.preventDefault();
    if (e.ctrlKey) {
      e.stopPropagation();
      const { value } = data;
      window.open(`/${value}`);
      return;
    }
    handleItemClick(e, data);
  };

  const logoutUser = () => {
    logout();
  };

  useEffect(() => {
    const hasJobbing = window.localStorage.getItem("accessJobbing");
    if (hasJobbing === "true") {
      setHasJobbingAccess(true);
    } else {
      setHasJobbingAccess(false);
    }
  }, []);

  const facilities = (
    <Menu.Item className="facilities">
      <Icon name="pin" className="set-icon" />
      <Dropdown item text="Facilities" className="user-menu custom-menu-link">
        <Dropdown.Menu>
          <Dropdown.Item
            value="inventory"
            active={activeItem === "inventory"}
            onClick={handleClick}
            as="a"
            href="/inventory"
          >
            <Icon name="chart bar" /> Inventory
          </Dropdown.Item>
          <Dropdown.Item
            value="asset"
            active={activeItem === "asset"}
            onClick={handleClick}
            as="a"
            href="/asset"
          >
            <Icon name="zip" /> Asset
          </Dropdown.Item>
          <Dropdown.Item
            name="schedule"
            className=""
            value="schedule"
            active={activeItem === "schedule"}
            onClick={handleClick}
            as="a"
            href="/schedule"
          >
            <Icon name="calendar times" /> Schedule
          </Dropdown.Item>
          <Dropdown.Item
            name="hoseMechanic"
            className=""
            value="hoseMechanic"
            active={activeItem === "hoseMechanic"}
            onClick={handleClick}
            as="a"
            href="/hoseMechanic"
          >
            <Icon name="cogs" /> Hose Mechanic
          </Dropdown.Item>
          <Dropdown.Item
            name="maintenanceForm"
            className=""
            value="maintenanceForm"
            active={activeItem === "maintenanceForm"}
            onClick={handleClick}
            as="a"
            href="/maintenanceForm"
          >
            <Icon name="wpforms" /> Inspection Form
          </Dropdown.Item>
          <Dropdown.Item
            name="crew"
            className=""
            value="crew"
            active={activeItem === "crew"}
            onClick={handleClick}
            as="a"
            href="/crew"
          >
            <Icon name="users" /> Crews
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );

  return (
    <Menu.Menu position="right" className={visibility}>
      <Menu.Item
        name="home"
        className="custom-menu-link"
        value="home"
        active={activeItem === "home"}
        onClick={handleClick}
        as="a"
        href="/home"
      >
        <Icon name="home" /> Home
      </Menu.Item>
      <Menu.Item
        name="products"
        className="custom-menu-link"
        value="search"
        active={activeItem === "search"}
        onClick={handleClick}
        as="a"
        href="/search"
      >
        <Icon name="search" /> Products
      </Menu.Item>
      <Menu.Item
        name="Cart"
        className="custom-menu-link user-facility-menu"
        value="cart"
        active={activeItem === "cart"}
        onClick={handleClick}
        as="a"
      >
        <Icon name="shopping cart" />
        Cart
        <Label color="green">{getCartCount()}</Label>
      </Menu.Item>
      <Popup
        content="Coming Soon!"
        position="top center"
        trigger={
          <Menu.Item
            name="Orders"
            className="custom-menu-link"
            value="orders"
            as="a"
          >
            <Icon name="file text" />
            Orders
          </Menu.Item>
        }
      />
      {hasJobbingAccess && (
        <Menu.Item
          name="Jobbing"
          className="custom-menu-link"
          value="jobbing"
          active={activeItem === "jobbing"}
          onClick={handleItemClick}
          as="a"
          href="/jobbing"
        >
          <Icon name="configure" />
          Jobbing
        </Menu.Item>
      )}
      <Menu.Item
        name="Profile"
        className="custom-menu-link"
        value="profile"
        active={activeItem === "profile"}
        onClick={handleItemClick}
        as="a"
        href="/profile"
      >
        <Icon name="user" />
        Profile
      </Menu.Item>
      <Menu.Item
        className="custom-menu-link"
        onClick={() => logoutUser()}
        as="a"
        style={{ position: "fixed", bottom: 50, left: 60, fontSize: 16 }}
      >
        Logout
      </Menu.Item>
      {isFacility && (
        <Menu.Item
          name="inventory"
          className=" custom-menu-link user-facility-menu"
          value="inventory"
          active={activeItem === "inventory"}
          onClick={handleClick}
        >
          <Icon name="chart bar" /> Inventory
        </Menu.Item>
      )}

      {isFacility && (
        <Menu.Item
          name="asset"
          className="custom-menu-link user-facility-menu"
          value="asset"
          active={activeItem === "asset"}
          onClick={handleItemClick}
        >
          <Icon name="zip" /> Asset
        </Menu.Item>
      )}

      {isFacility && (
        <Menu.Item
          name="asset"
          className="custom-menu-link user-facility-menu"
          value="asset"
          active={activeItem === "asset"}
          onClick={handleItemClick}
        >
          <Icon name="calendar times" /> Schedule
        </Menu.Item>
      )}
      <Menu.Item>{isFacility && facilities}</Menu.Item>
    </Menu.Menu>
  );
};

CommonMenu.propTypes = {
  activeItem: PropTypes.string,
  getCartCount: PropTypes.func,
  handleItemClick: PropTypes.func,
  isFacility: PropTypes.bool,
};

export default CommonMenu;
