import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Checkbox,
  Dimmer,
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Loader,
  Button,
  Header,
  Select,
  TextArea,
  Modal,
  Popup,
  Message,
} from "semantic-ui-react";
import "./orderHistory.css";
import { apiGetOrderDetails, apiGetOrderHistoryList } from "./orderHistory.api";
import MetaData from "../../components/MetaData";
import { useDraggable } from "react-use-draggable-scroll";
import { padZero, sortKeysWithDatesFirstDescending } from "../../utils/utils";
import { apiGetUsersListWithCurrent } from "../MyAssets/myAssets.api";
import _debounce from "lodash/debounce";
import rightArrow from "../../../src/assets/images/right-transparent.png";
import { showErrorToast } from "../../utils/toastUtils";
import Skeleton from "react-loading-skeleton";

const OrderHistoryNew = (props) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [technicianFilter, setTechnicianFilter] = useState("All");
  const [technicianFilterList, setTechnicianFilterList] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [firstSelectedId, setFirstSelectedId] = useState(0);
  const [technicianName, setTechnicianName] = useState("");
  const [orderHistoryList, setOrderHistoryList] = useState([]);
  const [technicianList, setTechnicianList] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [selectedData, setSelectedData] = useState({});

  const ref = useRef();
  const { events } = useDraggable(ref);

  const onSearch = async (search, technician) => {
    setSearchLoading(true);
    const result = await apiGetOrderHistoryList(search, technician);
    if (result) {
      setSearchLoading(false);
      const groupedByDateTime = result.reduce((result, item) => {
        var key = "";
        const date = item.confirmedDateTime
          ? new Date(item.confirmedDateTime)
          : "Unconfirmed"; // Use ISO string to ensure consistent keys

        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        if (date !== "Unconfirmed") {
          key = date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          });
        } else {
          key = "Unconfirmed";
        }
        // Check if the key already exists, if not, create an empty array
        result[key] = result[key] || [];
        result[key].push(item);

        return result;
      }, {});

      const orderedKeys = Object.keys(groupedByDateTime).sort((a, b) => {
        // Define the order of keys
        const order = {
          Overdue: -3,
          Today: -2,
          Tomorrow: 1,
          Unconfirmed: 100,
          Completed: 101,
        };

        // Compare keys based on the order defined
        return (
          (order[a] || Number.MAX_SAFE_INTEGER) -
          (order[b] || Number.MAX_SAFE_INTEGER)
        );
      });

      const sortedKeys = sortKeysWithDatesFirstDescending(orderedKeys);

      const orderedGrouped = sortedKeys.reduce((result, key) => {
        result[key] = groupedByDateTime[key];
        return result;
      }, {});

      // Iterate over the keys of the object
      for (const key in orderedGrouped) {
        // Check if the key is not in the excludeKeys array
        orderedGrouped[key].sort((a, b) => {
          if (a.confirmedDateTime === null) return 1; // Null confirmedDateTime goes to the end
          if (b.confirmedDateTime === null) return -1; // Null confirmedDateTime goes to the end
          return new Date(b.confirmedDateTime) - new Date(a.confirmedDateTime);
        });
      }

      console.log(orderedGrouped);
      setOrderHistoryList(orderedGrouped);
    }
  };

  const getUsersList = async () => {
    const result = await apiGetUsersListWithCurrent();
    if (result) {
      const list = result.filter(
        (item, index) => result.indexOf(item) === index
      );
      const filterList = result.filter(
        (item, index) => result.indexOf(item) === index
      );
      setTechnicianList(list);
      filterList.unshift({ text: "All", value: "All" });
      setTechnicianFilterList(filterList);
      setTechnicianFilter("");
    }
  };

  const getOrderData = async () => {
    setDataLoading(true);
    const result = await apiGetOrderDetails(selectedId);
    if (result) {
      setSelectedData(result);
      setDataLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setDataLoading(false);
    }
  };

  const debouncedHandleChange = _debounce((value) => {
    onSearch(value, technicianFilter);
    setSearchTerm(value);
  }, 500);

  const handleSearchChange = (event) => {
    const { value } = event.target;

    // Call the debounced function with the input value
    debouncedHandleChange(value);
  };

  useEffect(() => {
    if (technicianFilterList.length > 0) {
      onSearch(searchTerm, technicianFilter);
      var technician = technicianFilterList.find(function (element) {
        return element.value === technicianFilter;
      });
      if (technician) setTechnicianName(technician.text);
    }
  }, [technicianFilter]);

  const getJobbingListData = async () => {
    onSearch(searchTerm, technicianFilter);
  };

  useEffect(() => {
    if (selectedId !== 0) {
      getOrderData();
    } else {
      setSelectedData({});
    }
  }, [selectedCard]);

  useEffect(() => {
    // getJobbingListData();
    getUsersList();
  }, []);

  const orderListGrouped = Object.entries(orderHistoryList).map(
    ([category, list], index) => (
      <div style={{ marginTop: index === 0 ? 0 : 15 }}>
        <div style={{ marginBottom: 5 }}>
          <span
            style={{
              fontSize: 13,
              marginLeft: 0,
              fontWeight: "bolder",
            }}
          >
            {category}
          </span>
        </div>
        {list.map((data, i) => {
          if (index === 0 && i === 0 && firstSelectedId === 0) {
            setFirstSelectedId(data.Id);
            setSelectedId(data.id);
            setSelectedCard(data);
          }
          var date = "";
          if (data.confirmedDateTime !== null) {
            var dateObject = new Date(data.confirmedDateTime);

            // Format the date
            var formattedDate = `${padZero(dateObject.getDate())}/${padZero(
              dateObject.getMonth() + 1
            )}/${dateObject.getFullYear().toString().slice(2)}`;
            var hours = dateObject.getHours() % 12 || 12; // Convert to 12-hour format
            var formattedTime = `${padZero(hours)}:${padZero(
              dateObject.getMinutes()
            )} ${dateObject.getHours() < 12 ? "AM" : "PM"}`;
            // Combine date and time
            date = `${formattedDate}`;
          }
          return (
            <Card
              className={
                selectedId === data.id ? "order-card active" : "order-card"
              }
            >
              <Card.Content
                onClick={() => {
                  if (selectedId !== data.id) {
                    setSelectedData({});
                    setSelectedId(data.id);
                    setSelectedCard(data);
                  } else {
                    setSelectedId(0);
                    setSelectedCard({});
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <Grid columns={2} className="order-management">
                  <Grid.Row>
                    <Grid.Column
                      width={16}
                      style={{ paddingTop: 6, paddingBottom: 6 }}
                    >
                      <b>
                        <span style={{ fontSize: 17 }}>
                          {data.clientOrderRef}
                        </span>
                      </b>
                      <Card.Description style={{ marginTop: 7 }}>
                        <Icon
                          name="user"
                          style={{ color: "#66cc23", marginRight: 10 }}
                        />
                        {data.confirmedByName}
                      </Card.Description>
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: 13,
                          marginTop: -20,
                        }}
                      >
                        <span>{formattedTime}</span>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          );
        })}
      </div>
    )
  );

  return (
    <React.Fragment>
      <MetaData title="Order History" />
      <Grid
        columns={3}
        divided
        className="order-management"
        style={{ marginTop: -30 }}
      >
        <Grid.Row style={{ paddingTop: 0, zIndex: 1 }}>
          <Grid.Column width={16} style={{ paddingRight: 0 }}>
            {selectedCard?.clientOrderRef ? (
              <h3 style={{ marginLeft: 20 }}>
                <Icon name="configure" /> Order History -{" "}
                {selectedCard?.clientOrderRef}
              </h3>
            ) : (
              <h3 style={{ marginLeft: 20 }}>
                <Icon name="configure" /> Order History
              </h3>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column
            computer={4}
            tablet={6}
            mobile={16}
            className="order-list-section"
            style={{ paddingRight: 0 }}
          >
            <div className="order-header">
              <Input
                icon="search"
                placeholder="Search for your order..."
                style={{ width: "100%" }}
                size="small"
                onChange={handleSearchChange}
              />
            </div>
            <div
              className="order-status"
              style={{ paddingRight: "0px !important" }}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 8, paddingBottom: 10 }}
                  >
                    <Dropdown
                      style={{ fontSize: 13, width: "100%" }}
                      basic
                      color="black"
                      text={
                        technicianFilter
                          ? technicianName
                          : "Filter by technician"
                      }
                      search
                      scrolling
                      icon="user"
                      floating
                      labeled
                      button
                      className="icon"
                      options={technicianFilterList}
                      onChange={(e, data) => {
                        setTechnicianFilter(data.value);
                      }}
                      value={technicianFilter}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <div className="order-list">
              <div style={{ position: "absolute", top: 300, left: 80 }}>
                {searchLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  >
                    Loading Order History...
                  </Loader>
                ) : null}
              </div>
              <div
                {...events}
                ref={ref}
                className="order-list-scroll"
                disabled={searchLoading}
                style={{ opacity: searchLoading ? 0.2 : 1 }}
              >
                {orderListGrouped}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            computer={8}
            tablet={10}
            mobile={16}
            style={{ paddingRight: 0 }}
          >
            <div className="order-form">
              {selectedData?.userFullName ? (
                <div
                  className="order-form-content"
                  style={{ paddingRight: "0px !important" }}
                >
                  <Form size="tiny">
                    <Grid>
                      <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: 5,
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -8.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{ paddingTop: 5, paddingBottom: 0 }}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Details:
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          computer={16}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -10,
                          }}
                        >
                          <hr />
                        </Grid.Column>
                        <Grid.Column
                          computer={6}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -10,
                            paddingLeft: 45,
                          }}
                        >
                          <Form.Field>
                            <label style={{ color: "#66cc23" }}>
                              Order Number
                            </label>
                            <span>{selectedCard?.clientOrderRef}</span>
                          </Form.Field>
                          <Form.Field style={{ marginTop: "1.5rem" }}>
                            <label style={{ color: "#66cc23" }}>
                              Order Date & Time
                            </label>
                            <span>{selectedCard?.formattedDate}</span>
                          </Form.Field>
                          <Form.Field style={{ marginTop: "1.5rem" }}>
                            <label style={{ color: "#66cc23" }}>
                              Ordered by
                            </label>
                            <span>{selectedData?.userFullName}</span>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          computer={10}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -10,
                          }}
                        >
                          <Form.Field>
                            <label style={{ color: "#66cc23" }}>
                              Delivery Address
                            </label>
                            <span>{selectedData?.deliveryAddress}</span>
                          </Form.Field>
                          <Form.Field style={{ marginTop: "1.5rem" }}>
                            <label style={{ color: "#66cc23" }}>Notes</label>
                            <span>{selectedData?.deliveryNotes}</span>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -8.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{ paddingTop: "1rem", paddingBottom: 0 }}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Products:
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          computer={16}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: "1rem",
                            marginTop: -10,
                          }}
                        >
                          <hr />
                        </Grid.Column>
                        <Grid.Column
                          computer={10}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop: -10,
                            paddingLeft: 45,
                          }}
                        >
                          {selectedData?.orderLine.map((data) => {
                            return (
                              <Form.Field style={{ marginBottom: 20 }}>
                                <label style={{ fontSize: 16 }}>
                                  {data?.productName}
                                </label>
                                <Grid>
                                  <Grid.Column width={8}>
                                    <span style={{ color: "#66cc23" }}>
                                      {data?.barcode}
                                    </span>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    <span>
                                      QTY: {data?.productUomQty}{" "}
                                      {data?.uom.toUpperCase()}
                                    </span>
                                  </Grid.Column>
                                </Grid>
                                <hr style={{ marginRight: 0 }} />
                              </Form.Field>
                            );
                          })}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </div>
              ) : dataLoading ? (
                <Skeleton count={30} />
              ) : null}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

export default OrderHistoryNew;
