import axios from "axios";
import {
  ORDER,
  CREDIT,
  INITIATE_PAYMENT_GATEWAY,
  PLACE_PAYMENT_GATEWAY,
  CART,
  PICKUP_ADDRESS,
} from "../../constants/apiLink";

export const apiPlaceOrderCredit = (orderData) => {
  // ReactGA.event({
  //   category: 'Payment',
  //   action: 'Payment by BOA Credit',
  //   label: 'Payment BOA Credit label'
  // });

  const url = ORDER + "/partial";
  return axios.post(url, orderData).then(
    (result) => {
      return result;
    },
    (error) => {
      return error;
    }
  );
};

export const apiInitiatePaymentGatewayOrder = async (orderData) => {
  // ReactGA.event({
  //   category: 'Payment',
  //   action: 'Payment by Payment Gateway',
  //   label: 'Payment by Payment Gateway label'
  // });

  const url = ORDER + INITIATE_PAYMENT_GATEWAY;
  return await axios.post(url, orderData).then(
    (data) => {
      return data;
    },
    (error) => {
      return error;
    }
  );
};

export const apiUpdatePaymentResultAndUpdateOrder = async (paymentResult) => {
  const url = ORDER + PLACE_PAYMENT_GATEWAY;
  return await axios.post(url, paymentResult);
};

export const apiGetOverallDiscount = async () => {
  const url = CART + "/overallDiscount";
  return await axios.get(url, (result) => {
    return result;
  });
};

export const apiGetPickUpAddress = async () => {
  const url = PICKUP_ADDRESS;
  return await axios.get(url, (result) => {
    return result;
  });
};
