import _ from "lodash";
import React, { useEffect, Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Search,
  Grid,
  Card,
  Button,
  Pagination,
  Label,
  Message,
  Icon,
  Divider,
} from "semantic-ui-react";
import "./search.css";
import cx from "classnames";
import Tree from "react-ui-tree";

import "../../../node_modules/react-ui-tree/dist/react-ui-tree.css";
import * as searchReducer from "./search.reducer";
import Filter from "./Filter";
import NarrowFilter from "./NarrowFilters";
import DefaultGrid from "./DefaultGrid";
import SideNav from "./SideNav";
import Description from "./Description";
import SearchProductPlaceholder from "./SearchProductPlaceholder";
import MetaData from "../../components/MetaData";
import ResultRenderer from "./ResultRenderer";
import UserRoleCheck from "../../components/UserRoleCheck";
import FittingsFilter from "./FittingsFilter";
import * as searchUtils from "./search.utils.js";
import QuickSearch from "../../components/QuickSearch/index.js";

const FILTER_PARAM_FILTER = "filter";
const FILTER_PARAM_VALUE = "value";

class SearchComponent extends Component {
  debounceTimeout;
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isSearchLoading: false,
      value: "",
      activePage: 1,
      activeNode: null,
      filter: "",
      dataSource: null,
      searchMessage: null,
      isLoadingCategories: false,
    };

    this.openNode = [];
  }
  //
  // getFilterValues = async (node) => {
  //   const { module: value, level } = node;
  //   if (level === "secondaryGrp") {
  //     if (value.indexOf(FITTINGS_HYD) > -1) {
  //       await this.props.actionSetFittingThreadTypes();
  //     }
  //     if (value.indexOf(ADAPTORS_HYD) > -1) {
  //       await this.props.actionSetAdaptorThreadTypes();
  //     }
  //   }
  // }

  componentWillMount = () => {
    const urlParams = this.getUrlParams();
    this.clearThreadFilters();

    if (urlParams) {
      this.prePopulatePage(urlParams);
      return;
    }
    this.resetComponent();
  };

  componentDidMount = () => {
    // this.props.actionSetAccountDetails();
  };

  componentDidUpdate = (prevProps) => {
    // if (this.props.searchTerm !== prevProps.searchTerm) {
    //   const category = this.props.categories.find(
    //     (category) => category.module === this.props.module
    //   );
    //   this.onClickCategory(category);
    //   this.setState({ value: "", searchMessage: null });
    // }

    if (this.props.location === prevProps.location) {
      return;
    }

    const urlParams = this.getUrlParams() || {};
    const { value, filter } = this.state;

    if (urlParams.value === value && urlParams.filter === filter) {
      return;
    }

    if (urlParams) {
      this.prePopulatePage(urlParams);
    }
  };

  getUrlParams = () => {
    let url = document.location.href;
    url = new URL(url);
    const { searchParams } = url;
    const filter = searchParams.get(FILTER_PARAM_FILTER);
    const value = searchParams.get(FILTER_PARAM_VALUE);

    if (!!filter || !!value) {
      return { value, filter };
    }

    return null;
  };

  prePopulatePage = async ({ value = "", filter = "" }) => {
    this.setState({
      filter,
      value,
    });
    await this.populateFilterTree();
    await this.callSearchApi(value, filter, 1, false);
  };

  resetComponent = async () => {
    await this.props.actionClearSearch();
    await this.populateFilterTree();
  };

  populateFilterTree = async () => {
    this.setState({ isLoadingCategories: true });
    await this.props.actionSetCategories();
    const { categories } = this.props;

    const tree = {
      module: "Categories",
      collapsed: false,
      children: categories,
    };

    this.setState({
      dataSource: tree,
      isLoadingCategories: false,
    });
  };

  handleResultSelect = (e, { result }) => {
    const value = result.barcode;
    const { filter } = this.state;
    this.setState({ value, filter: "", active: null, activeNode: null });

    this.callSearchApi(value, null, 1);
  };

  onClickNode = async (node) => {
    await this.clearThreadFilters();
    this.setState({
      filter: node.value,
      active: node,
      activeNode: node,
    });
    this.callSearchApi(this.state.value, node.value, 1, false, node);
  };

  renderNode = (node) => {
    const { module = "" } = this.state.active ? this.state.active : "";
    if (node.module === "Categories") {
      return (
        <span className="node" onClick={() => {}}>
          Categories
        </span>
      );
    }

    return (
      <div
        className={cx("node", {
          "is-active": node.module === module,
          "secondary-group": node.level === "secondaryGrp",
          "tertiary-group": node.level === "tertiaryGrp",
        })}
        onClick={this.onClickNode.bind(null, node)}
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        {node.module}
      </div>
    );
  };

  addParamsToUrl = ({ value, filter, activePage }) => {
    value = !value ? "" : value;
    filter = !filter ? "" : _.replace(filter, "&", "$and$");

    this.props.history.push({
      pathname: "/search",
      search: `?${FILTER_PARAM_FILTER}=${filter}&${FILTER_PARAM_VALUE}=${value}`,
    });
  };

  callSearchApi = async (
    value,
    filter,
    activePage,
    pushToHistory = true,
    activeNode = null
  ) => {
    if (pushToHistory && !this.props.isHoseMaker) {
      this.addParamsToUrl({ value, filter, activePage });
    }
    const { threadType1, threadSize1, threadType2, threadSize2 } = this.props;

    this.setState({ isLoading: true, activePage });

    const result = await this.props.actionSearchProducts(
      value,
      filter,
      activePage,
      activeNode,
      threadType1,
      threadSize1,
      threadType2,
      threadSize2
    );
    const { data } = result;
    const { secondaryGrpDisplayName, tertiaryGrpDisplayName } = data;
    const module = tertiaryGrpDisplayName
      ? tertiaryGrpDisplayName
      : secondaryGrpDisplayName;

    const active = {
      module,
    };
    this.computeOpenFilterItems(secondaryGrpDisplayName, active);
    this.setState({
      isLoading: false,
    });
  };

  onSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ isLoading: true });
    const { value, filter } = this.state;
    await this.callSearchApi(value, filter, 1);
    this.setState({
      searchMessage: null,
      isLoading: false,
    });
  };

  handleSearchChange = async (e, { value }) => {
    this.setState({ value, searchMessage: null });
    if (value === "") {
      this.setState({
        filter: "",
        active: null,
        activeNode: null,
        isSearchLoading: false,
      });
      await this.props.actionClearSearch();
      await this.populateFilterTree();
      return false;
    }
    clearTimeout(this.debounceTimeout);
    e.preventDefault();

    this.debounceTimeout = setTimeout(async () => {
      this.setState({
        isSearchLoading: true,
      });
      const { filter } = this.state;
      await this.props.actionAutoComplete(value, null, this.props.searchTerm);
      this.setState({
        isSearchLoading: false,
      });
    }, 500);
  };

  handleSearchButtonClick = async (e) => {
    e.preventDefault();

    // if (_.isEmpty(value)) {
    //   this.setState({
    //     searchMessage: "Please enter product details to search",
    //     isLoading: false
    //   });

    //   return;
    // }

    this.onSubmit(e);
  };

  handlePaginationChange = (...params) => {
    const { searchresult } = this.props;
    const { totalPages } = searchresult;

    if (totalPages === 1) {
      return;
    }

    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { value, filter } = this.state;

    this.setState({
      activePage,
    });

    this.callSearchApi(value, filter, activePage, false);
  };

  clearThreadFilters = async () => {
    await this.props.actionSetThreadType1(undefined);
    await this.props.actionSetThreadSize1(undefined);
    await this.props.actionSetThreadType2(undefined);
    await this.props.actionSetThreadSize2(undefined);
  };

  clearFilters = async () => {
    await this.clearThreadFilters();

    this.setState({
      activePage: 1,
      totalPages: 1,
      filter: "",
      value: "",
    });
    this.callSearchApi("", "", 1);
  };

  applyFilter = (node) => {
    if (!node) {
      this.setState({
        filter: "",
        active: null,
        activeNode: null,
      });
      this.props.actionClearSearch();
      return false;
    }

    const { value } = this.state;

    this.setState({
      filter: node ? node.value : "",
      active: node,
      activeNode: node,
    });

    this.callSearchApi(value, node ? node.value : "", 1, false, node);
  };

  applyFilter2 = (filter) => {
    const { activeNode, value } = this.state;
    const nodes = activeNode.value.split(",");
    const idx = nodes.indexOf(filter);
    const level = nodes.slice(0, idx);
    activeNode.value = level.toString();
    this.setState({
      activePage: 1,
      totalPages: 1,
      filter: "",
      activeNode: activeNode,
    });
    // const { value } = this.state;
    this.callSearchApi(value, level.toString(), 1, false);
  };

  handleChange = (e, value) => {
    const treeState = e;

    let newNodes = [];

    for (let index = 0; index < treeState.children.length; index++) {
      const element = treeState.children[index];
      if (element.collapsed === false) {
        newNodes.push(element.module);
      }
    }

    const difference = _.difference(newNodes, this.openNode);

    this.openNode = difference;

    // for (let index = 0; index < treeState.children.length; index++) {
    //   const element = treeState.children[index];
    //   if (
    //     element.collapsed === false &&
    //     element.module !== _.head(this.openNode)
    //   ) {
    //     element.collapsed = true;
    //   }
    // }

    this.setState({
      dataSource: treeState,
    });
  };

  computeOpenFilterItems = (secondaryGrp, active) => {
    let { dataSource } = this.state;

    if (dataSource && dataSource.children) {
      for (let index = 0; index < dataSource.children.length; index++) {
        const element = dataSource.children[index];
        if (element.module === secondaryGrp) {
          element.collapsed = false;
        } else {
          element.collapsed = true;
        }
      }

      this.setState({
        dataSource,
        active,
      });
    }
  };

  onClickCategory = async (node) => {
    await this.clearThreadFilters();
    this.setState({
      filter: node.value,
      active: node,
      activeNode: node,
    });
    this.callSearchApi(this.state.value, node.value, 1, false, node);
  };

  onNarrowFilterClickHandler = (node) => {
    this.setState({
      filter: node.value,
      active: node,
      activeNode: node,
    });
    this.callSearchApi(this.state.value, node.value, 1, false, node);
  };

  goToHoseMaker = () => {
    this.props.history.push("/hoseMaker");
  };

  threadType1Handler = async (data) => {
    const { value } = data;
    const { filter } = this.state;
    await this.props.actionSetThreadType1(value);
    this.callSearchApi(this.state.value, filter, 1);
  };

  threadSize1Handler = async (data) => {
    const { value } = data;
    const { filter } = this.state;
    await this.props.actionSetThreadSize1(value);
    this.callSearchApi(this.state.value, filter, 1);
  };

  threadType2Handler = async (data) => {
    const { value } = data;
    const { filter } = this.state;
    await this.props.actionSetThreadType2(value);
    this.callSearchApi(this.state.value, filter, 1);
  };

  threadSize2Handler = async (data) => {
    const { filter } = this.state;
    const { value } = data;
    await this.props.actionSetThreadSize2(value);
    this.callSearchApi(this.state.value, filter, 1);
  };

  clearThreadFilterHandler = async () => {
    await this.props.actionSetThreadType1(undefined);
    await this.props.actionSetThreadSize1(undefined);
    await this.props.actionSetThreadType2(undefined);
    await this.props.actionSetThreadSize2(undefined);

    const { filter } = this.state;
    this.callSearchApi(this.state.value, filter, 1);
  };

  productClickHandler = (e, stockCode) => {
    e.preventDefault();
    if (e.ctrlKey) {
      e.stopPropagation();
      window.open(`/search?filter=&value=${stockCode}`);
      return;
    }
  };

  render() {
    const {
      isLoading,
      value,
      filter,
      searchMessage,
      isLoadingCategories,
      activeNode,
      isSearchLoading,
    } = this.state;

    const {
      narrowFilters = [],
      result = [],
      totalPages = 1,
      totalRecords = 0,
      secondaryGrpDisplayName = undefined,
      tertiaryGrpDisplayName = undefined,
      productFamilyDisplayName = undefined,
      threadFilters = {},
    } = this.props.searchresult;

    const {
      autoCompleteResult = [],
      categories,
      accountDetails,
      userInfo,
      allBoxPrice = [],
    } = this.props;
    const {
      account = {},
      showPriceToUser = false,
      boahubShowRrp,
      boahubShowPurchasePrice,
    } = userInfo;
    const { showPrice = false } = account;
    const {
      physicalThreadSize1,
      threadTypeFilter1,
      threadTypeFilter2,
      physicalThreadSize2,
    } = threadFilters;
    const { threadType1, threadSize1, threadType2, threadSize2 } = this.props;

    const searchResults = result;
    const { fullBoxCoilPercent } = accountDetails;

    if (categories < 1) {
    } else {
      if (this.props.isHoseMaker && filter.length === 0 && this.props.module) {
        const category = categories.find(
          (category) => category.module === this.props.module
        );
        this.onClickCategory(category);
      }
    }

    return (
      <Grid className="product-search" style={{ marginTop: 0 }}>
        {!this.props.isHoseMaker && <MetaData title="Products" />}
        {!this.props.isHoseMaker && (
          <h3>
            <Icon name="search" /> Products -{" "}
            {localStorage.getItem("stockLocationName")}
          </h3>
        )}
        <Grid.Row
          columns={this.props.isHoseMaker ? 1 : 2}
          style={{ marginTop: 0 }}
        >
          {/* Left Tree */}
          {this.props.isHoseMaker ? null : (
            <Grid.Column tablet={4} computer={4} only="computer, tablet">
              {isLoadingCategories && (
                <div className="treeBox">
                  <h4>
                    Loading categories tree <Icon loading name="spinner" />{" "}
                  </h4>
                </div>
              )}
              {this.state.dataSource && (
                <Tree
                  paddingLeft={10}
                  tree={this.state.dataSource}
                  isNodeCollapsed={this.isNodeCollapsed}
                  onChange={this.handleChange}
                  renderNode={this.renderNode}
                />
              )}
              <SideNav goToHoseMaker={this.goToHoseMaker} />
            </Grid.Column>
          )}
          {/* Search Box */}
          <Grid.Column
            tablet={12}
            computer={this.props.isHoseMaker ? 16 : 12}
            mobile={16}
          >
            {this.props.isHoseMaker ? null : (
              <form onSubmit={this.onSubmit}>
                <Grid>
                  <Grid.Column tablet={16} computer={12} mobile={16}>
                    {/* Search bar */}
                    <Search
                      className="search-box"
                      loading={isSearchLoading}
                      placeholder="Search products by code or description"
                      resultRenderer={ResultRenderer}
                      onResultSelect={this.handleResultSelect}
                      onSearchChange={this.handleSearchChange}
                      noResultsMessage={
                        isSearchLoading
                          ? "Searching..."
                          : "No direct matches found."
                      }
                      results={isSearchLoading ? [] : autoCompleteResult}
                      value={value}
                      {...this.props}
                    />
                  </Grid.Column>
                  <Grid.Column
                    tablet={4}
                    computer={2}
                    mobile={6}
                    className="no-top-padded"
                    style={{ paddingRight: 0 }}
                  >
                    {isLoading ? (
                      <Button loading>Search</Button>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: "#66cc23",
                          color: "#fff",
                          width: "100%",
                        }}
                        onClick={_.debounce(this.handleSearchButtonClick, 500, {
                          leading: true,
                        })}
                      >
                        Search
                      </Button>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    tablet={2}
                    computer={2}
                    mobile={8}
                    className="no-top-padded"
                  >
                    <Button onClick={this.clearFilters}>Clear</Button>
                  </Grid.Column>
                </Grid>
              </form>
            )}
            {
              <FittingsFilter
                category={secondaryGrpDisplayName}
                threadTypes1={threadTypeFilter1}
                threadTypes2={threadTypeFilter2}
                selectedThreadSize1={physicalThreadSize1}
                selectedThreadSize2={physicalThreadSize2}
                threadType1Handler={this.threadType1Handler}
                threadType2Handler={this.threadType2Handler}
                threadSize1Handler={this.threadSize1Handler}
                threadSize2Handler={this.threadSize2Handler}
                clearThreadFilterHandler={this.clearThreadFilterHandler}
                filtersApplied={{
                  threadType1,
                  threadSize1,
                  threadType2,
                  threadSize2,
                }}
              />
            }

            {/* Search Warning message */}
            {searchMessage && (
              <Message info>
                <Message.Header>{searchMessage}</Message.Header>
              </Message>
            )}

            {/* Show Filters applied */}
            {!this.props.isHoseMaker && (
              <Filter
                {...{
                  secondaryGrpDisplayName,
                  tertiaryGrpDisplayName,
                  productFamilyDisplayName,
                  activeNode,
                  categories,
                  isLoading,
                }}
                applyFilter={this.applyFilter}
              />
            )}

            {this.props.isHoseMaker && (
              <Grid>
                <Grid.Column width={10}></Grid.Column>
                <Grid.Column
                  width={6}
                  style={{
                    paddingBottom: this.props.marginTop
                      ? this.props.marginTop
                      : -20,
                    zIndex: 1001,
                    paddingLeft: 0,
                  }}
                >
                  <form onSubmit={this.onSubmit}>
                    <QuickSearch
                      style={{ fontSize: 14 }}
                      className="search-box"
                      loading={isSearchLoading}
                      placeholder="Search products by code or description"
                      resultRenderer={ResultRenderer}
                      onResultSelect={this.handleResultSelect}
                      onSearchChange={this.handleSearchChange}
                      noResultsMessage={
                        isSearchLoading
                          ? "Searching..."
                          : "No direct matches found."
                      }
                      results={isSearchLoading ? [] : autoCompleteResult}
                      value={value}
                      {...this.props}
                    />
                  </form>
                </Grid.Column>
                <Grid.Column
                  width={16}
                  style={{ marginTop: -40, zIndex: 1000 }}
                >
                  <Filter
                    {...{
                      secondaryGrpDisplayName,
                      tertiaryGrpDisplayName,
                      productFamilyDisplayName,
                      activeNode,
                      categories,
                      isLoading,
                    }}
                    applyFilter={this.applyFilter}
                  />
                </Grid.Column>
              </Grid>
            )}

            {/* Show total Result count*/}
            {searchResults.length && !this.props.isHoseMaker ? (
              <div className="relevant-product-count">
                Found {totalRecords} products. Showing the most relevant first.
              </div>
            ) : null}

            {/* Show narrow down filter*/}
            {!isLoading && narrowFilters && narrowFilters.length > 0 ? (
              <NarrowFilter
                narrowFilters={narrowFilters}
                onClickHandler={this.onNarrowFilterClickHandler}
              />
            ) : null}

            {!isLoading && searchResults.length !== 0 ? (
              <p>
                <br />
                {/* <i>* All prices shown are exclusive of GST </i>{" "} */}
              </p>
            ) : null}

            {!isLoading &&
              searchResults.length !== 0 &&
              _.map(searchResults, (item, index, key) => {
                const { stockDescription, stockCode, currency, pricingPolicy } =
                  item;
                const discountObj = allBoxPrice[index];
                const { price } = pricingPolicy;
                return (
                  <Card
                    link
                    style={{ width: "100%" }}
                    key={stockCode + key}
                    className="product-card"
                  >
                    <Card.Content textAlign="left">
                      {showPriceToUser ? (
                        <Card.Header>
                          {showPriceToUser ? (
                            <Label color="green" ribbon="right">
                              <UserRoleCheck type={"price"}>
                                {currency || "NZD "}{" "}
                                {searchUtils.precise_round(price, 2)}
                              </UserRoleCheck>
                            </Label>
                          ) : null}
                          <p
                            className="product-header"
                            onClick={(event) =>
                              this.productClickHandler(event, stockCode)
                            }
                          >
                            <span className="product-header-stock">
                              {stockCode}
                            </span>
                            {stockDescription}
                          </p>
                        </Card.Header>
                      ) : (
                        <Card.Header
                          style={{ position: "relative", minHeight: "75px" }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "25px",
                              right: "0px",
                              textAlign: "right",
                            }}
                          >
                            {item.retailPriceStr && !this.props.isHoseMaker && (
                              <p style={{ fontSize: "small", color: "gray" }}>
                                RRP{" "}
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {item.retailPriceStr}
                                </span>
                              </p>
                            )}
                            {item.buyPriceStr && !this.props.isHoseMaker && (
                              <p style={{ fontSize: "small", color: "gray" }}>
                                BUY{" "}
                                <span
                                  style={{
                                    color: "black",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {item.buyPriceStr}
                                </span>
                              </p>
                            )}
                          </div>
                          {showPriceToUser ? (
                            <Label color="green" ribbon="right">
                              <UserRoleCheck type={"price"}>
                                {currency || "NZD "}{" "}
                                {searchUtils.precise_round(price, 2)}
                              </UserRoleCheck>
                            </Label>
                          ) : null}
                          <p
                            style={{ paddingTop: "30px" }}
                            className="product-header"
                            onClick={(event) =>
                              this.productClickHandler(event, stockCode)
                            }
                          >
                            <span className="product-header-stock">
                              {stockCode}
                            </span>
                            {stockDescription}
                          </p>
                        </Card.Header>
                      )}

                      <Card.Description className="card-description">
                        <Description
                          key={key}
                          {...{ item }}
                          {...{ discountObj }}
                          fullBoxCoilPercent={fullBoxCoilPercent}
                          tertiaryGrpDisplayName={tertiaryGrpDisplayName}
                          isHoseMaker={this.props.isHoseMaker}
                          onProductClick={this.props.onProductClick}
                        />
                      </Card.Description>
                    </Card.Content>
                  </Card>
                );
              })}

            {isLoading && <SearchProductPlaceholder />}

            {searchResults.length ? (
              <Pagination
                activePage={this.state.activePage}
                boundaryRange={1}
                onPageChange={this.handlePaginationChange}
                size="mini"
                totalPages={totalPages}
                ellipsisItem={true}
                firstItem={null}
                lastItem={null}
              />
            ) : null}

            {this.state.activeNode?.value?.includes(",") &&
              searchResults.length === 0 &&
              (filter || value) && (
                <Message info>
                  <Message.Header>
                    No Products found. Try widening your search criteria or get
                    in touch with BOA for Assistance.
                  </Message.Header>
                  <p>
                    <Button onClick={() => this.props.history.goBack()}>
                      {" "}
                      Back{" "}
                    </Button>
                  </p>
                </Message>
              )}

            {searchResults.length === 0 && !filter && (
              <DefaultGrid
                categories={categories}
                onClickHandler={this.onClickCategory}
                isLoadingCategories={isLoadingCategories}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.search.categories,
    allBoxPrice: state.search.allBoxPrice,
    searchresult: state.search.searchResult,
    autoCompleteResult: state.search.autoCompleteResult,
    userInfo: state.auth.user,
    fittingThreadTypes: state.search.fittingThreadTypes,
    adaptorsThreadTypes: state.search.adaptorsThreadTypes,
    threadType1: state.search.threadType1,
    threadType2: state.search.threadType2,
    threadSize1: state.search.threadSize1,
    threadSize2: state.search.threadSize2,
    threadTypes1: state.search.threadTypes1,
    threadTypes2: state.search.threadTypes2,
    accountDetails: state.search.accountDetails,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(searchReducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
